.chica-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px; /* Espacio entre las imágenes */
  padding: 10px;
}

.chica-gallery-item {
  position: relative;
}
.catalogo {
  text-align: center;
}

.talla-section {
  position: relative;
  background-image: url('../assets/img/fondocatalogo.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Ocupa toda la pantalla */
  display: flex;
  align-items: center;
  justify-content: center; /* Centra las columnas horizontalmente */
  width: 100%; /* Asegura que la sección ocupe el ancho completo */
  padding: 0; /* Elimina el padding por defecto */
}



.row {
  width: 100%; /* Asegura que Row ocupe el ancho completo */
  margin: 0; /* Elimina los márgenes por defecto */
  z-index: 1; /* Coloca las columnas sobre el overlay */
}

.column {
  position: relative; /* Necesario para posicionar el botón */
  padding: 0; /* Elimina el padding por defecto */
}

.column-image {
  width: 100%; /* Ocupa el 100% del ancho de la columna */
  height: 100vh; /* Ocupa el 100% de la altura de la pantalla */
  object-fit: cover; /* Ajusta la imagen para que cubra su contenedor */
}

.talla-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%; /* Ajusta el tamaño del botón */
  height:98%; /* Ajusta el tamaño del botón */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  border: none;
  border-radius: 8%; /* Hace el botón redondo */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s; /* Añade una transición para el efecto hover */
  z-index: 3; /* Asegura que el botón esté sobre el overlay */
}

.talla-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: translate(-50%, -50%) scale(1.1); /* Escala el botón en hover */
  color:white!important;
}

.MuiButtonBase-root
{z-inde:5;}




/* Media queries para dispositivos móviles */
@media (max-width: 767px) {
  .talla-section {
    height: 100%; /* Ajusta la altura en móviles */
  }

  .column-image {
    height: 100vh; /* Asegura que la imagen se ajuste a la columna */
    
  }

  .talla-button {
  
    font-size: 1em; /* Ajusta el tamaño del texto en el botón */
    width: 60%; /* Ajusta el tamaño del botón */
    height:30%; /* Ajusta el tamaño del botón */
    border-radius: 50%; /* Hace el botón redondo */
    background-color: rgba(61, 6, 110, 0.7);
    text-shadow:4px 4px 2px rgba(0,0,0,0.6);
    font-size:24px;
  }
.arrow-button
{
  font-size:2em;
 
}




} /*Fin de Dispositivos Pequeños*/


/* Media queries para dispositivos móviles */
@media (min-width: 768px) {
 
.arrow-button
{
  display:none;
  .chica-gallery {
    padding: 20px;
  }

} 

}/*Fin Dispositivos grandes*/

/*img{
  border-top-left-radius: 0%;
  border-top-right-radius: 25%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 25%;
}*/


.chica-gallery {
  padding: 20px;
}

.chica-gallery-item {
  position: relative;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.send-button {
  position: absolute;
 
  top: 10px;
  right: 10px;
 
  background-color: rgba(37, 211, 102, 0.8);
  border: none;
  box-shadow: 0 2px 8px rgba(37, 211, 102 0.15);
}