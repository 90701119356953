

  
  
  .logo {
    position: relative; /* Fija la posición del logo */
    top: 10px; /* Espacio desde el borde superior */
    left: 10px; /* Espacio desde el borde izquierdo */
   height: auto;
    max-width:3%;
    z-index: 2; /* Asegura que el logo esté por encima de otros elementos */
  }

  /* Estilos generales para .main-row */
.main-row {
    height: 100vh; /* Asegura que la fila ocupe toda la altura de la pantalla */
    padding: 0; /* Elimina relleno alrededor de la fila */
    overflow: hidden;
  }
  
  /* Estilos para dispositivos móviles y verticales */
  @media (max-width: 767px) {
    .main-row {
      
    }
  }
  
  /* Estilos para computadoras y dispositivos horizontales */
  @media (min-width: 768px) {
    .main-row {
      margin-left: 0; /* Márgenes cero para pantallas más grandes */
      margin-right: 0;
    }

    .modal {
     
    }
  }

/* Define keyframes for fade-in and fade-out animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.ant-modal-content
{
 margin-right:5%;
 margin-left:5%;
 margin-top:55%;
 letter-spacing:2px;
 
}

