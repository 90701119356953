/* src/components/Live.css */
.webcam-section {
    position: relative;
    width: 100%;
    height: 100vh; /* 100% de la altura de la ventana */
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  

  /* src/components/Live.css */
.webcam-section {
    position: relative;
    width: 100%;
    height: 100vh; /* 100% de la altura de la ventana */
    overflow: hidden;
    color: white;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  
  .live-indicator {
    position: absolute;
    top: 10px;
    left: 25px;
    font-size: 24px;
    font-weight: bold;
    animation: blink 1s infinite;
    color:red;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .current-time {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px #d7d3d3;
  }
  
  .float-button {
    position: absolute;
    bottom: 10px;
    left: 20px;
    background-color: rgba(177, 140, 254, 0.5);
     border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 50px;
    margin-bottom: 60px;
   
  }

  /* Estilos para la ventana emergente */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
  }
  
  .modal-content .ant-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color:#3d066e!important;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content .ant-button:hover {
    background-color: #3d066e!important;
  }
  