@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');

body{font-family: 'Jost', sans-serif;}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta el video para cubrir todo el contenedor */
  z-index: -1; /* Asegura que el video esté detrás del contenido */
  overflow: hidden;
}

.header11 {
  position: relative;
  width: 100%;
  height: 100vh; /* Ajusta la altura de la sección al 100% de la pantalla */
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5)!important; /* Ajusta el color y la opacidad del overlay aquí */
  z-index: 0; /* Asegura que el overlay esté sobre el video */
  overflow: hidden;
}

/*
  .image-wrapper {
    position: relative;
    overflow: hidden;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 767.98px) { 
    .image-wrapper {
      height: 100vh;
    }
  
    .responsive-image {
      width: 100%;
      height: 100vh;
      object-fit: cover; 
    }
  }*/
 
  .mbr-text
  {
    color:#3d066e!important;
  }